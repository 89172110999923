<template>
  <div>
    <b-modal ref="my-modal" centered modal-class="modal-warning" hide-footer>
      <b-card-text>
        <h4
          v-for="(modalItem, index) in modalItems"
          :key="index"
          class="font-weight-bolder mb-1"
        >
          <b-badge class="badgeMargin">
            {{ modalItem }}
          </b-badge>
        </h4>
      </b-card-text>
    </b-modal>
    <b-modal
      ref="my-modal-social"
      centered
      modal-class="modal-warning"
      hide-footer
    >
      <b-card-text>
        <h4
          v-for="(modalItem, index) in modalItems"
          :key="index"
          class="font-weight-bolder mb-1"
        >
          <b-badge class="badgeMargin">
            {{ modalItem.type }}
          </b-badge>
        </h4>
      </b-card-text>
    </b-modal>
    <b-modal
      ref="my-modal-info"
      centered
      modal-class="modal-warning"
      hide-footer
    >
      <b-card-text class="pre-formatted">
        {{ modalItemInfo }}
      </b-card-text>
    </b-modal>
    <b-modal
      ref="my-modal-with-key"
      centered
      modal-class="modal-warning"
      hide-footer
    >
      <b-card-text>
        <h4
          v-for="(modalItem, index) in modalItems"
          :key="index"
          class="font-weight-bolder mb-1"
        >
          <b-badge class="badgeMargin">
            {{ index }}
          </b-badge>
          =>
          <b-badge class="badgeMargin">
            {{ modalItem }}
          </b-badge>
        </h4>
      </b-card-text>
    </b-modal>
    <b-row>
      <information-channel :infromation-channel="infromationChannel" />
      <video-section-charts
        :v_score="v_score"
        :correct_video="correct_video"
        :need_to_check_video="need_to_check_video"
        :count_video="count_video"
      />
      <video-section-tables />
      <b-col cols="12">
        <b-card class="w-100" no-body>
          <b-card-header>
            <div class="pt-1">
              <b-card-sub-title>
                <h4>{{ $t("Pages.Assistant.Title.vid_analyt") }}</h4>
              </b-card-sub-title>
            </div>
          </b-card-header>
          <b-card-body>
            <b-table
              :items="tableDataVideos"
              responsive
              :fields="fieldsVideos"
              class="mb-0"
              :empty-text="
                $t('Pages.Assistant.Table.no_record_found')
              "
            >
              <template #cell(title)="data">
                <b-link
                  :href="`https://youtube.com/watch?v=${data.item.video_id}`"
                  target="_blank"
                >
                  <div
                    class="d-flex align-items-center"
                    v-b-tooltip.hover.top="data.item.title"
                  >
                    <div class="ml-1">
                      <div class="font-weight-bolder">
                        {{ data.item.title.slice(0, 70) }}
                      </div>
                    </div>
                  </div>
                </b-link>
              </template>
              <template #cell(title_length)="data">
                <div class="d-flex align-items-center">
                  <div class="ml-1">
                    <div class="font-weight-bolder">
                      <h4
                        class="font-weight-bolder mb-0"
                        v-b-tooltip.hover.top="
                          $t(
                            'Pages.Assistant.Title.length_of_title'
                          )
                        "
                      >
                        <b-avatar
                          size="32"
                          :variant="
                            data.item.score_detalis.title_length
                              ? 'light-success'
                              : 'light-danger'
                          "
                        >
                          <feather-icon
                            :icon="
                              data.item.score_detalis.title_length
                                ? 'CheckCircleIcon'
                                : 'InfoIcon'
                            "
                          />
                        </b-avatar>
                      </h4>
                    </div>
                  </div>
                </div>
              </template>
              <template #cell(custom_thumbnail)="data">
                <div class="d-flex align-items-center">
                  <div class="ml-1">
                    <div class="font-weight-bolder">
                      <h4
                        class="font-weight-bolder mb-0"
                        v-b-tooltip.hover.top="
                          $t(
                            'Pages.Assistant.Title.have_cover'
                          )
                        "
                      >
                        <b-avatar
                          size="32"
                          :variant="
                            data.item.score_detalis.custom_thumbnail
                              ? 'light-success'
                              : 'light-danger'
                          "
                        >
                          <feather-icon
                            :icon="
                              data.item.score_detalis.custom_thumbnail
                                ? 'CheckCircleIcon'
                                : 'InfoIcon'
                            "
                          />
                        </b-avatar>
                      </h4>
                    </div>
                  </div>
                </div>
              </template>
              <template #cell(timestamp)="data">
                <div class="d-flex align-items-center">
                  <div class="ml-1">
                    <div class="font-weight-bolder">
                      <h4
                        class="font-weight-bolder mb-0"
                        v-b-tooltip.hover.top="
                          $t(
                            'Pages.Assistant.Title.have_timing'
                          )
                        "
                      >
                        <b-avatar
                          size="32"
                          :variant="
                            data.item.score_detalis.timestamp
                              ? 'light-success'
                              : 'light-danger'
                          "
                        >
                          <feather-icon
                            :icon="
                              data.item.score_detalis.timestamp
                                ? 'CheckCircleIcon'
                                : 'InfoIcon'
                            "
                          />
                        </b-avatar>
                      </h4>
                    </div>
                  </div>
                </div>
              </template>
              <template #cell(tags)="data">
                <div class="d-flex align-items-center">
                  <div class="ml-1">
                    <div class="font-weight-bolder">
                      <h4
                        class="font-weight-bolder mb-0"
                        v-b-tooltip.hover.top="
                          $t(
                            'Pages.Assistant.Title.have_tag'
                          )
                        "
                        @click="showModal(data.item.tagsInDescription)"
                      >
                        <b-avatar
                          size="32"
                          :variant="
                            data.item.score_detalis.tags
                              ? 'light-success'
                              : 'light-danger'
                          "
                        >
                          <feather-icon
                            :icon="
                              data.item.score_detalis.tags
                                ? 'CheckCircleIcon'
                                : 'InfoIcon'
                            "
                          />
                        </b-avatar>
                      </h4>
                    </div>
                  </div>
                </div>
              </template>
              <template #cell(subscription_link)="data">
                <div class="d-flex align-items-center">
                  <div class="ml-1">
                    <div class="font-weight-bolder">
                      <h4
                        class="font-weight-bolder mb-0"
                        v-b-tooltip.hover.top="
                          $t(
                            'Pages.Assistant.Title.no_direct_link'
                          )
                        "
                      >
                        <b-avatar
                          size="32"
                          :variant="
                            data.item.score_detalis.subscription_link
                              ? 'light-success'
                              : 'light-danger'
                          "
                        >
                          <feather-icon
                            :icon="
                              data.item.score_detalis.subscription_link
                                ? 'CheckCircleIcon'
                                : 'InfoIcon'
                            "
                          />
                        </b-avatar>
                      </h4>
                    </div>
                  </div>
                </div>
              </template>
              <template #cell(social_links_description)="data">
                <div class="d-flex align-items-center">
                  <div class="ml-1">
                    <div class="font-weight-bolder">
                      <h4
                        class="font-weight-bolder mb-0"
                        v-b-tooltip.hover.top="
                          $t(
                            'Pages.Assistant.Title.have_link_social'
                          )
                        "
                        @click="showModalSocial(data.item.socialLinks)"
                      >
                        <b-avatar
                          size="32"
                          :variant="
                            data.item.score_detalis.social_links_description
                              ? 'light-success'
                              : 'light-danger'
                          "
                        >
                          <feather-icon
                            :icon="
                              data.item.score_detalis.social_links_description
                                ? 'CheckCircleIcon'
                                : 'InfoIcon'
                            "
                          />
                        </b-avatar>
                      </h4>
                    </div>
                  </div>
                </div>
              </template>
              <template #cell(title_in_desc)="data">
                <div class="d-flex align-items-center">
                  <div class="ml-1">
                    <div class="font-weight-bolder">
                      <h4
                        class="font-weight-bolder mb-0"
                        v-b-tooltip.hover.top="
                          $t(
                            'Pages.Assistant.Title.title_mentioned'
                          )
                        "
                        @click="showModal(data.item.description)"
                      >
                        <b-avatar
                          size="32"
                          :variant="
                            data.item.score_detalis.title_in_desc
                              ? 'light-success'
                              : 'light-danger'
                          "
                        >
                          <feather-icon
                            :icon="
                              data.item.score_detalis.title_in_desc
                                ? 'CheckCircleIcon'
                                : 'InfoIcon'
                            "
                          />
                        </b-avatar>
                      </h4>
                    </div>
                  </div>
                </div>
              </template>

              <template #cell(hashtags_description)="data">
                <div class="d-flex align-items-center">
                  <div class="ml-1">
                    <div class="font-weight-bolder">
                      <h4
                        class="font-weight-bolder mb-0"
                        v-b-tooltip.hover.top="
                          $t(
                            'Pages.Assistant.Title.have_hashtag'
                          )
                        "
                        @click="showModal(data.item.hashtags)"
                      >
                        <b-avatar
                          size="32"
                          :variant="
                            data.item.score_detalis.hashtags_description
                              ? 'light-success'
                              : 'light-danger'
                          "
                        >
                          <feather-icon
                            :icon="
                              data.item.score_detalis.hashtags_description
                                ? 'CheckCircleIcon'
                                : 'InfoIcon'
                            "
                          />
                        </b-avatar>
                      </h4>
                    </div>
                  </div>
                </div>
              </template>

              <template #cell(channel_name_in_desc)="data">
                <div class="d-flex align-items-center">
                  <div class="ml-1">
                    <div class="font-weight-bolder">
                      <h4
                        class="font-weight-bolder mb-0"
                        v-b-tooltip.hover.top="
                          $t(
                            'Pages.Assistant.Title.ch_name_in_desc'
                          )
                        "
                        @click="showModal(data.item.description)"
                      >
                        <b-avatar
                          size="32"
                          :variant="
                            data.item.score_detalis.channel_name_in_desc
                              ? 'light-success'
                              : 'light-danger'
                          "
                        >
                          <feather-icon
                            :icon="
                              data.item.score_detalis.channel_name_in_desc
                                ? 'CheckCircleIcon'
                                : 'InfoIcon'
                            "
                          />
                        </b-avatar>
                      </h4>
                    </div>
                  </div>
                </div>
              </template>

              <template #cell(tags_description)="data">
                <div class="d-flex align-items-center">
                  <div class="ml-1">
                    <div class="font-weight-bolder">
                      <h4
                        class="font-weight-bolder mb-0"
                        v-b-tooltip.hover.top="
                          $t(
                            'Pages.Assistant.Title.have_tag'
                          )
                        "
                        @click="showModal(data.item.tagsInDescription)"
                      >
                        <b-avatar
                          size="32"
                          :variant="
                            data.item.score_detalis.tags_description
                              ? 'light-success'
                              : 'light-danger'
                          "
                        >
                          <feather-icon
                            :icon="
                              data.item.score_detalis.tags_description
                                ? 'CheckCircleIcon'
                                : 'InfoIcon'
                            "
                          />
                        </b-avatar>
                      </h4>
                    </div>
                  </div>
                </div>
              </template>

              <template #cell(resolution)="data">
                <div class="d-flex align-items-center">
                  <div class="ml-1">
                    <div class="font-weight-bolder">
                      <h4
                        class="font-weight-bolder mb-0"
                        v-b-tooltip.hover.top="
                          $t(
                            'Pages.Assistant.Title.hd_quality'
                          )
                        "
                      >
                        <b-avatar
                          size="32"
                          :variant="
                            data.item.score_detalis.resolution
                              ? 'light-success'
                              : 'light-danger'
                          "
                        >
                          <feather-icon
                            :icon="
                              data.item.score_detalis.resolution
                                ? 'CheckCircleIcon'
                                : 'InfoIcon'
                            "
                          />
                        </b-avatar>
                      </h4>
                    </div>
                  </div>
                </div>
              </template>
            </b-table>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        sm="12"
        v-if="pages > 1"
        class="d-flex align-items-center justify-content-center justify-content-sm-end"
      >
        <b-pagination-nav
          :link-gen="linkGen"
          :number-of-pages="pages"
          use-router
          class="mb-0"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BForm,
  BButton,
  BCard,
  BAlert,
  BFormDatepicker,
  BInputGroup,
  BInputGroupAppend,
  BCardText,
  BAvatar,
  BTable,
  BImg,
  BBadge,
  BLink,
  BTooltip,
  BMedia,
  BMediaAside,
  BMediaBody,
  BCardHeader,
  BModal,
  BCardBody,
  BOverlay,
  BCardTitle,
  BPaginationNav,
  BCardSubTitle,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import VueApexCharts from "vue-apexcharts";
import chartjsData from "./components/chartjsData";
import informationChannel from "./components/information-channel.vue";
import videoSectionCharts from "./components/video-section-charts.vue";
import videoSectionTables from "./components/video-section-tables.vue";

export default {
  components: {
    VueApexCharts,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BForm,
    BButton,
    BCard,
    BAlert,
    BFormDatepicker,
    BInputGroup,
    BInputGroupAppend,
    BCardText,
    BAvatar,
    BTable,
    BImg,
    BBadge,
    BLink,
    BTooltip,
    BMedia,
    BMediaAside,
    BMediaBody,
    BCardHeader,
    BModal,
    BCardBody,
    BOverlay,
    BCardTitle,
    BPaginationNav,
    BCardSubTitle,
    informationChannel,
    videoSectionCharts,
    videoSectionTables,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      infromationChannel: {},
      fieldsVideos: [
        {
          key: "title",
          label: this.$t("Pages.Assistant.Table.Title"),
          tdClass: "tdClassMax",
        },
        {
          key: "title_length",
          label: this.$t("Pages.Assistant.Table.title_length"),
          tdClass: "tdClassMin",
        },
        {
          key: "custom_thumbnail",
          label: this.$t("Pages.Assistant.Table.Thumbnail"),
          tdClass: "tdClassMin",
        },
        {
          key: "timestamp",
          label: this.$t("Pages.Assistant.Table.Timestamp"),
          tdClass: "tdClassMin",
        },
        {
          key: "tags",
          label: `${this.$t("Pages.Assistant.Table.Tags")}`,
          tdClass: "tdClassMax",
        },
        {
          key: "subscription_link",
          label: this.$t("Pages.Assistant.Table.Subscription Link"),
          tdClass: "tdClassMin",
        },
        {
          key: "social_links_description",
          label: this.$t("Pages.Assistant.Table.Socials"),
          tdClass: "tdClassMax",
        },
        {
          key: "title_in_desc",
          label: this.$t("Pages.Assistant.Table.title_in_desc"),
          tdClass: "tdClassMin",
        },
        {
          key: "hashtags_description",
          label: this.$t("Pages.Assistant.Table.Hashtags"),
          tdClass: "tdClassMax",
        },
        {
          key: "channel_name_in_desc",
          label: this.$t("Pages.Assistant.Table.ch_name_in_desc"),
          tdClass: "tdClassMin",
        },
        {
          key: "tags_description",
          label: `${this.$t("Pages.Assistant.Table.tag_in_desc")}`,
          tdClass: "tdClassMax",
        },
        {
          key: "resolution",
          label: this.$t("Pages.Assistant.Table.Resolution"),
          tdClass: "tdClassMin",
        },
      ],
      tableDataVideos: [],
      pages: 0,
      modalItems: [],
      v_score: [0],
      need_to_check_video: 0,
      correct_video: 0,
      count_video: 0,
      modalItemInfo:null
    };
  },
  created() {
    this.getChannelInformation();
    this.getAnalyticsAllVideos();
  },
  watch: {
    $route(to, from) {
      if (to.query != from.query) {
        this.getAnalyticsAllVideos();
        setTimeout(() => {
          window.scrollTo(0, document.body.scrollHeight);
        }, 2000);
      }
    },
  },
  methods: {
    linkGen(pageNum) {
      return pageNum === 1 ? `?` : `?page=${pageNum}`;
    },
    getChannelInformation() {
      this.$http
        .get(
          `/api/tools/assistant/channel-information?id=${this.$route.params.channel_id}&uuid=${this.$route.params.uuid}`
        )
        .then((response) => {
          this.infromationChannel = response.data;
        });
    },
    getAnalyticsAllVideos() {
      let page = this.$route.query.page ? this.$route.query.page : 1;
      this.$http
        .get(
          `/api/tools/assistant/table/video-analytics/all-videos?id=${this.$route.params.channel_id}&uuid=${this.$route.params.uuid}&page=${page}`
        )
        .then((response) => {
          this.tableDataVideos = response.data.videos.data;
          this.pages = response.data.videos.last_page;
          this.v_score = [response.data.score];
          this.need_to_check_video = response.data.need_to_check_video;
          this.correct_video = response.data.correct_video;
          this.count_video = response.data.count_video;
        });
    },
    showModal(items) {
      this.modalItems = items;
      this.$refs["my-modal"].show();
    },
    showModalWithKey(items) {
      this.modalItems = items;
      this.$refs["my-modal-with-key"].show();
    },
    showModalInfo(modalItemInfo) {
      this.modalItemInfo = modalItemInfo;
      this.$refs["my-modal-info"].show();
    },
    showModalSocial(items) {
      this.modalItems = items;
      this.$refs["my-modal-social"].show();
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/base/bootstrap-extended/include";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
@import "@core/scss/vue/pages/page-profile.scss";

.min-width {
  width: 100px;
  height: auto;
}

text {
  letter-spacing: normal;
}

.min-height {
  height: 170px !important;
}
</style>

<template>
  <b-row cols="12" class="mx-0">
    <b-col lg="4" sm="12">
      <b-card no-body>
        <b-card-header>
          <h4 class="mb-0">{{ $t("Pages.Assistant.Title.v_score") }}</h4>
        </b-card-header>
        <!-- apex chart -->
        <b-row>
          <vue-apex-charts
            type="radialBar"
            height="200"
            class="my-2 w-100"
            :options="chartjsData.scoreMasterTube"
            :series="v_score"
          />
        </b-row>
        <b-row class="text-center mx-0 pb-1">
          <b-col
            cols="4"
            class="border-top d-flex align-items-between border-right flex-column py-1"
          >
            <b-card-text class="text-muted mb-2">
              {{ $t("Pages.Assistant.Title.count_vid") }}
            </b-card-text>
            <h3 class="font-weight-bolder mb-0">
              {{ count_video }}
            </h3>
          </b-col>
          <b-col
            cols="4"
            class="border-top d-flex align-items-between border-right flex-column py-1"
          >
            <b-card-text class="text-muted mb-2">
              {{ $t("Pages.Assistant.Title.correct_vid") }}
            </b-card-text>
            <h3 class="font-weight-bolder mb-0">
              {{ correct_video }}
            </h3>
          </b-col>
          <b-col
            cols="4"
            class="border-top d-flex align-items-between flex-column py-1"
          >
            <b-card-text class="text-muted mb-2">
              {{ $t("Pages.Assistant.Title.need_check_vid") }}
            </b-card-text>
            <h3 class="font-weight-bolder mb-0">
              {{ need_to_check_video }}
            </h3>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
    <channel-status />
    <b-col sm="12" md="6" v-if="video_lang">
      <b-card no-body>
        <b-card-header>
          <div class="pt-1">
            <b-card-sub-title>
              <h4>{{ $t("Pages.Assistant.Title.video_longs") }}</h4>
            </b-card-sub-title>
          </div>
        </b-card-header>
        <b-card-body>
          <chartjs-component-line-chart
            :height="350"
            :data="chartjsData.video_lang.data"
            :options="chartjsData.video_lang.options"
            :plugins="plugins"
          />
        </b-card-body>
      </b-card>
    </b-col>
    <b-col sm="12" md="6" v-if="video_short">
      <b-card no-body>
        <b-card-header>
          <div class="pt-1">
            <b-card-sub-title>
              <h4>{{ $t("Pages.Assistant.Title.video_shorts") }}</h4>
            </b-card-sub-title>
          </div>
        </b-card-header>
        <b-card-body>
          <chartjs-component-line-chart
            :height="350"
            :data="chartjsData.video_short.data"
            :options="chartjsData.video_short.options"
            :plugins="plugins"
          />
        </b-card-body>
      </b-card>
    </b-col>
    <b-col sm="12" md="6" v-if="video_live">
      <b-card no-body>
        <b-card-header>
          <div class="pt-1">
            <b-card-sub-title>
              <h4>{{ $t("Pages.Assistant.Title.video_live") }}</h4>
            </b-card-sub-title>
          </div>
        </b-card-header>
        <b-card-body>
          <chartjs-component-line-chart
            :height="350"
            :data="chartjsData.video_live.data"
            :options="chartjsData.video_live.options"
            :plugins="plugins"
          />
        </b-card-body>
      </b-card>
    </b-col>
    <b-col sm="12" md="6" v-if="video_all">
      <b-card no-body>
        <b-card-header>
          <div class="pt-1">
            <b-card-sub-title>
              <h4>{{ $t("Pages.Assistant.Title.video_all") }}</h4>
            </b-card-sub-title>
          </div>
        </b-card-header>
        <b-card-body>
          <chartjs-component-line-chart
            :height="350"
            :data="chartjsData.video_all.data"
            :options="chartjsData.video_all.options"
            :plugins="plugins"
          />
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BCardText,
  BAvatar,
  BTable,
  BImg,
  BBadge,
  BLink,
  BTooltip,
  BMedia,
  BMediaAside,
  BMediaBody,
  BCardHeader,
  BCardBody,
  BOverlay,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BForm,
  BButton,
  BCard,
  BAlert,
  BFormDatepicker,
  BInputGroup,
  BCardTitle,
  BModal,
  BInputGroupAppend,
  BPaginationNav,
  BCardSubTitle,
} from "bootstrap-vue";
import chartjsData from "./chartjsData";
import ChartjsComponentLineChart from "../../charts/charts-components/ChartjsComponentLineChart.vue";
import ChartjsComponentDoughnutChart from "./charts/ChartjsComponentDoughnutChart.vue";
import VueApexCharts from "vue-apexcharts";
import scoreMasterTube from "../components/score-master-tube.vue";
import channelStatus from "../components/channel-status.vue";

export default {
  name: "section-charts",
  components: {
    BRow,
    BCol,
    BCardText,
    BAvatar,
    BTable,
    BImg,
    BBadge,
    BLink,
    BTooltip,
    BMedia,
    BMediaAside,
    BMediaBody,
    BCardHeader,
    BCardBody,
    BOverlay,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BForm,
    BButton,
    BCard,
    BAlert,
    BFormDatepicker,
    BInputGroup,
    BCardTitle,
    BModal,
    BInputGroupAppend,
    BPaginationNav,
    BCardSubTitle,
    ChartjsComponentLineChart,
    ChartjsComponentDoughnutChart,
    VueApexCharts,
    scoreMasterTube,
    channelStatus,
  },
  props: {
    v_score: {
      default: [0],
    },
    need_to_check_video: {
      default: 0,
    },
    correct_video: {
      default: 0,
    },
    count_video: {
      default: 0,
    },
  },
  data() {
    return {
      like: false,
      dislike: false,
      share: false,
      comment: false,
      video_lang: false,
      video_short: false,
      video_live: false,
      video_all: false,
      chartjsData,
      plugins: [
        {
          beforeInit(chart) {
            chart.legend.afterFit = function () {
              this.height += 20;
            };
          },
        },
      ],
    };
  },
  async mounted() {
    this.getCountLike();
    this.getCountDislike();
    this.getCountShare();
    this.getCountComment();
    this.getCountVideoLang();
    this.getCountVideoShort();
    this.getCountVideoLive();
    this.getCountVideoAll();
  },
  methods: {
    getCountLike() {
      this.$http
        .get(
          `/api/tools/assistant/charts/like?id=${this.$route.params.channel_id}&uuid=${this.$route.params.uuid}`
        )
        .then(({ data }) => {
          this.chartjsData.like.options.scales.yAxes[0].ticks.max =
            Math.max(...data.count_like_30_days, ...data.count_like_60_days) *
            2;
          this.chartjsData.like.data.labels = data.labels;
          this.chartjsData.like.data.datasets[0].label = this.$t(
            "Pages.Assistant.Title.current_month"
          );
          this.chartjsData.like.data.datasets[0].data = data.count_like_30_days;
          this.chartjsData.like.data.datasets[1].label = this.$t(
            "Pages.Assistant.Title.preview_month"
          );
          this.chartjsData.like.data.datasets[1].data = data.count_like_60_days;
          this.like = true;
        });
    },
    getCountDislike() {
      this.$http
        .get(
          `/api/tools/assistant/charts/dislike?id=${this.$route.params.channel_id}&uuid=${this.$route.params.uuid}`
        )
        .then(({ data }) => {
          this.chartjsData.dislike.options.scales.yAxes[0].ticks.max =
            Math.max(
              ...data.count_dislike_30_days,
              ...data.count_dislike_60_days
            ) * 2;
          this.chartjsData.dislike.data.labels = data.labels;
          this.chartjsData.dislike.data.datasets[0].label = this.$t(
            "Pages.Assistant.Title.current_month"
          );
          this.chartjsData.dislike.data.datasets[0].data =
            data.count_dislike_30_days;
          this.chartjsData.dislike.data.datasets[1].label = this.$t(
            "Pages.Assistant.Title.preview_month"
          );
          this.chartjsData.dislike.data.datasets[1].data =
            data.count_dislike_60_days;
          this.dislike = true;
        });
    },
    getCountShare() {
      this.$http
        .get(
          `/api/tools/assistant/charts/share?id=${this.$route.params.channel_id}&uuid=${this.$route.params.uuid}`
        )
        .then(({ data }) => {
          this.chartjsData.share.options.scales.yAxes[0].ticks.max =
            Math.max(...data.count_share_30_days, ...data.count_share_60_days) *
            2;
          this.chartjsData.share.data.labels = data.labels;
          this.chartjsData.share.data.datasets[0].label = this.$t(
            "Pages.Assistant.Title.current_month"
          );
          this.chartjsData.share.data.datasets[0].data =
            data.count_share_30_days;
          this.chartjsData.share.data.datasets[1].label = this.$t(
            "Pages.Assistant.Title.preview_month"
          );
          this.chartjsData.share.data.datasets[1].data =
            data.count_share_60_days;
          this.share = true;
        });
    },
    getCountComment() {
      this.$http
        .get(
          `/api/tools/assistant/charts/comment?id=${this.$route.params.channel_id}&uuid=${this.$route.params.uuid}`
        )
        .then(({ data }) => {
          this.chartjsData.comment.options.scales.yAxes[0].ticks.max =
            Math.max(
              ...data.count_comment_30_days,
              ...data.count_comment_60_days
            ) * 2;
          this.chartjsData.comment.data.labels = data.labels;
          this.chartjsData.comment.data.datasets[0].label = this.$t(
            "Pages.Assistant.Title.current_month"
          );
          this.chartjsData.comment.data.datasets[0].data =
            data.count_comment_30_days;
          this.chartjsData.comment.data.datasets[1].label = this.$t(
            "Pages.Assistant.Title.preview_month"
          );
          this.chartjsData.comment.data.datasets[1].data =
            data.count_comment_60_days;
          this.comment = true;
        });
    },
    getCountVideoLang() {
      this.$http
        .get(
          `/api/tools/assistant/charts/lang-video?id=${this.$route.params.channel_id}&uuid=${this.$route.params.uuid}`
        )
        .then(({ data }) => {
          this.chartjsData.video_lang.options.scales.yAxes[0].ticks.max =
            Math.max(
              ...data.count_video_lang_30_days,
              ...data.count_video_lang_60_days
            ) * 2;
          this.chartjsData.video_lang.data.labels = data.labels;
          this.chartjsData.video_lang.data.datasets[0].label = this.$t(
            "Pages.Assistant.Title.current_month"
          );
          this.chartjsData.video_lang.data.datasets[0].data =
            data.count_video_lang_30_days;
          this.chartjsData.video_lang.data.datasets[1].label = this.$t(
            "Pages.Assistant.Title.preview_month"
          );
          this.chartjsData.video_lang.data.datasets[1].data =
            data.count_video_lang_60_days;
          this.video_lang = true;
        });
    },
    getCountVideoShort() {
      this.$http
        .get(
          `/api/tools/assistant/charts/shorts-video?id=${this.$route.params.channel_id}&uuid=${this.$route.params.uuid}`
        )
        .then(({ data }) => {
          this.chartjsData.video_short.options.scales.yAxes[0].ticks.max =
            Math.max(
              ...data.count_video_short_30_days,
              ...data.count_video_short_60_days
            ) * 2;
          this.chartjsData.video_short.data.labels = data.labels;
          this.chartjsData.video_short.data.datasets[0].label = this.$t(
            "Pages.Assistant.Title.current_month"
          );
          this.chartjsData.video_short.data.datasets[0].data =
            data.count_video_short_30_days;
          this.chartjsData.video_short.data.datasets[1].label = this.$t(
            "Pages.Assistant.Title.preview_month"
          );
          this.chartjsData.video_short.data.datasets[1].data =
            data.count_video_short_60_days;
          this.video_short = true;
        });
    },
    getCountVideoLive() {
      this.$http
        .get(
          `/api/tools/assistant/charts/live-video?id=${this.$route.params.channel_id}&uuid=${this.$route.params.uuid}`
        )
        .then(({ data }) => {
          this.chartjsData.video_live.options.scales.yAxes[0].ticks.max =
            Math.max(
              ...data.count_video_live_30_days,
              ...data.count_video_live_60_days
            ) * 2;
          this.chartjsData.video_live.data.labels = data.labels;
          this.chartjsData.video_live.data.datasets[0].label = this.$t(
            "Pages.Assistant.Title.current_month"
          );
          this.chartjsData.video_live.data.datasets[0].data =
            data.count_video_live_30_days;
          this.chartjsData.video_live.data.datasets[1].label = this.$t(
            "Pages.Assistant.Title.preview_month"
          );
          this.chartjsData.video_live.data.datasets[1].data =
            data.count_video_live_60_days;
          this.video_live = true;
        });
    },
    getCountVideoAll() {
      this.$http
        .get(
          `/api/tools/assistant/charts/all-video?id=${this.$route.params.channel_id}&uuid=${this.$route.params.uuid}`
        )
        .then(({ data }) => {
          this.chartjsData.video_all.options.scales.yAxes[0].ticks.max =
            Math.max(
              ...data.count_video_all_30_days,
              ...data.count_video_all_60_days
            ) * 2;
          this.chartjsData.video_all.data.labels = data.labels;
          this.chartjsData.video_all.data.datasets[0].label = this.$t(
            "Pages.Assistant.Title.current_month"
          );
          this.chartjsData.video_all.data.datasets[0].data =
            data.count_video_all_30_days;
          this.chartjsData.video_all.data.datasets[1].label = this.$t(
            "Pages.Assistant.Title.preview_month"
          );
          this.chartjsData.video_all.data.datasets[1].data =
            data.count_video_all_60_days;
          this.video_all = true;
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/chart-apex.scss";
</style>

<template>
  <b-row class="mx-0 w-100 match-height video-analytics-vue">
    <b-col sm="12" md="6">
      <b-card class="w-100" no-body>
        <b-card-header>
          <div class="pt-1">
            <b-card-sub-title>
              <h4>{{ $t("Pages.Assistant.Title.top_vid_view") }}</h4>
            </b-card-sub-title>
          </div>
        </b-card-header>
        <b-card-body>
          <b-table
            :items="tableDataViews"
            responsive
            :fields="fieldsViews"
            class="mb-0"
            :empty-text="$t('Pages.Assistant.Table.no_vid_found')"
          >
            <template #cell(title)="data">
              <b-link>
                <div class="d-flex align-items-center">
                  <b-avatar rounded size="32" variant="light-company">
                    <b-img
                      class="img-fluid"
                      :src="data.item.thumbnail"
                      alt="avatar img"
                    />
                  </b-avatar>
                  <div class="ml-1">
                    <div class="font-weight-bolder secondary-text">
                      {{ data.item.name }}
                    </div>
                  </div>
                </div>
              </b-link>
            </template>
            <template #cell(count)="data">
              <div class="d-flex flex-column">
                <span class="font-weight-bolder mb-25 orange-text">{{
                  data.item.count
                }}</span>
              </div>
            </template>
          </b-table>
        </b-card-body>
      </b-card>
    </b-col>
    <b-col sm="12" md="6">
      <b-card class="w-100" no-body>
        <b-card-header>
          <div class="pt-1">
            <b-card-sub-title>
              <h4>
                {{ $t("Pages.Assistant.Title.top_vid_comment") }}
              </h4>
            </b-card-sub-title>
          </div>
        </b-card-header>
        <b-card-body>
          <b-table
            :items="tableDataComments"
            responsive
            :fields="fieldsComments"
            class="mb-0"
            :empty-text="$t('Pages.Assistant.Table.no_vid_found')"
          >
            <template #cell(title)="data">
              <b-link>
                <div class="d-flex align-items-center">
                  <b-avatar rounded size="32" variant="light-company">
                    <b-img
                      class="img-fluid"
                      :src="data.item.thumbnail"
                      alt="avatar img"
                    />
                  </b-avatar>
                  <div class="ml-1">
                    <div class="font-weight-bolder secondary-text">
                      {{ data.item.name }}
                    </div>
                  </div>
                </div>
              </b-link>
            </template>
            <template #cell(count)="data">
              <div class="d-flex flex-column">
                <span class="font-weight-bolder mb-25 orange-text">{{
                  data.item.count
                }}</span>
              </div>
            </template>
          </b-table>
        </b-card-body>
      </b-card>
    </b-col>
    <b-col sm="12" md="6">
      <b-card class="w-100" no-body>
        <b-card-header>
          <div class="pt-1">
            <b-card-sub-title>
              <h4>{{ $t("Pages.Assistant.Title.top_vid_like") }}</h4>
            </b-card-sub-title>
          </div>
        </b-card-header>
        <b-card-body>
          <b-table
            :items="tableDataLikes"
            responsive
            :fields="fieldsLikes"
            class="mb-0"
            :empty-text="$t('Pages.Assistant.Table.no_vid_found')"
          >
            <template #cell(title)="data">
              <b-link>
                <div class="d-flex align-items-center">
                  <b-avatar rounded size="32" variant="light-company">
                    <b-img
                      class="img-fluid"
                      :src="data.item.thumbnail"
                      alt="avatar img"
                    />
                  </b-avatar>
                  <div class="ml-1">
                    <div class="font-weight-bolder secondary-text">
                      {{ data.item.name }}
                    </div>
                  </div>
                </div>
              </b-link>
            </template>
            <template #cell(count)="data">
              <div class="d-flex flex-column">
                <span class="font-weight-bolder mb-25 orange-text">{{
                  data.item.count
                }}</span>
              </div>
            </template>
          </b-table>
        </b-card-body>
      </b-card>
    </b-col>
    <b-col sm="12" md="6">
      <b-card class="w-100" no-body>
        <b-card-header>
          <div class="pt-1">
            <b-card-sub-title>
              <h4>
                {{ $t("Pages.Assistant.Title.top_vid_dislike") }}
              </h4>
            </b-card-sub-title>
          </div>
        </b-card-header>
        <b-card-body>
          <b-table
            :items="tableDataDislikes"
            responsive
            :fields="fieldsDislikes"
            class="mb-0"
            :empty-text="$t('Pages.Assistant.Table.no_vid_found')"
          >
            <template #cell(title)="data">
              <b-link>
                <div class="d-flex align-items-center">
                  <b-avatar rounded size="32" variant="light-company">
                    <b-img
                      class="img-fluid"
                      :src="data.item.thumbnail"
                      alt="avatar img"
                    />
                  </b-avatar>
                  <div class="ml-1">
                    <div class="font-weight-bolder secondary-text">
                      {{ data.item.name }}
                    </div>
                  </div>
                </div>
              </b-link>
            </template>
            <template #cell(count)="data">
              <div class="d-flex flex-column">
                <span class="font-weight-bolder mb-25 orange-text">{{
                  data.item.count
                }}</span>
              </div>
            </template>
          </b-table>
        </b-card-body>
      </b-card>
    </b-col>
    <b-col sm="12" md="6">
      <b-card class="w-100" no-body>
        <b-card-header>
          <div class="pt-1">
            <b-card-sub-title>
              <h4>
                {{ $t("Pages.Assistant.Title.top_vid_share") }}
              </h4>
            </b-card-sub-title>
          </div>
        </b-card-header>
        <b-card-body>
          <b-table
            :items="tableDataShares"
            responsive
            :fields="fieldsShares"
            class="mb-0"
            :empty-text="$t('Pages.Assistant.Table.no_vid_found')"
          >
            <template #cell(title)="data">
              <b-link>
                <div class="d-flex align-items-center">
                  <b-avatar rounded size="32" variant="light-company">
                    <b-img
                      class="img-fluid"
                      :src="data.item.thumbnail"
                      alt="avatar img"
                    />
                  </b-avatar>
                  <div class="ml-1">
                    <div class="font-weight-bolder secondary-text">
                      {{ data.item.name }}
                    </div>
                  </div>
                </div>
              </b-link>
            </template>
            <template #cell(count)="data">
              <div class="d-flex flex-column">
                <span class="font-weight-bolder mb-25 orange-text">{{
                  data.item.count
                }}</span>
              </div>
            </template>
          </b-table>
        </b-card-body>
      </b-card>
    </b-col>
    <b-col sm="12" md="6">
      <b-card class="w-100" no-body>
        <b-card-header>
          <div class="pt-1">
            <b-card-sub-title>
              <h4>
                {{
                  $t(
                    "Pages.Assistant.Title.top_vid_min_watch"
                  )
                }}
              </h4>
            </b-card-sub-title>
          </div>
        </b-card-header>
        <b-card-body>
          <b-table
            :items="tableDataEstimatedMinutesWatched"
            responsive
            :fields="fieldsEstimatedMinutesWatched"
            :empty-text="$t('Pages.Assistant.Table.no_vid_found')"
            class="mb-0"
          >
            <template #cell(title)="data">
              <b-link>
                <div class="d-flex align-items-center">
                  <b-avatar rounded size="32" variant="light-company">
                    <b-img
                      class="img-fluid"
                      :src="data.item.thumbnail"
                      alt="avatar img"
                    />
                  </b-avatar>
                  <div class="ml-1">
                    <div class="font-weight-bolder secondary-text">
                      {{ data.item.name }}
                    </div>
                  </div>
                </div>
              </b-link>
            </template>
            <template #cell(count)="data">
              <div class="d-flex flex-column">
                <span class="font-weight-bolder mb-25 orange-text">{{
                  data.item.count
                }}</span>
              </div>
            </template>
          </b-table>
        </b-card-body>
      </b-card>
    </b-col>
    <b-col sm="12" md="6">
      <b-card class="w-100" no-body>
        <b-card-header>
          <div class="pt-1">
            <b-card-sub-title>
              <h4>
                {{
                  $t(
                    "Pages.Assistant.Title.top_vid_sub_gained"
                  )
                }}
              </h4>
            </b-card-sub-title>
          </div>
        </b-card-header>
        <b-card-body>
          <b-table
            :items="tableDataSubscribersGained"
            responsive
            :fields="fieldsSubscribersGained"
            class="mb-0"
            :empty-text="$t('Pages.Assistant.Table.no_vid_found')"
          >
            <template #cell(title)="data">
              <b-link>
                <div class="d-flex align-items-center">
                  <b-avatar rounded size="32" variant="light-company">
                    <b-img
                      class="img-fluid"
                      :src="data.item.thumbnail"
                      alt="avatar img"
                    />
                  </b-avatar>
                  <div class="ml-1">
                    <div class="font-weight-bolder secondary-text">
                      {{ data.item.name }}
                    </div>
                  </div>
                </div>
              </b-link>
            </template>
            <template #cell(count)="data">
              <div class="d-flex flex-column">
                <span class="font-weight-bolder mb-25 orange-text">{{
                  data.item.count
                }}</span>
              </div>
            </template>
          </b-table>
        </b-card-body>
      </b-card>
    </b-col>
    <b-col sm="12" md="6">
      <b-card class="w-100" no-body>
        <b-card-header>
          <div class="pt-1">
            <b-card-sub-title>
              <h4>
                {{
                  $t("Pages.Assistant.Title.top_vid_sub_lost")
                }}
              </h4>
            </b-card-sub-title>
          </div>
        </b-card-header>
        <b-card-body>
          <b-table
            :items="tableDataSubscribersLost"
            responsive
            :fields="fieldsSubscribersLost"
            class="mb-0"
            :empty-text="$t('Pages.Assistant.Table.no_vid_found')"
          >
            <template #cell(title)="data">
              <b-link>
                <div class="d-flex align-items-center">
                  <b-avatar rounded size="32" variant="light-company">
                    <b-img
                      class="img-fluid"
                      :src="data.item.thumbnail"
                      alt="avatar img"
                    />
                  </b-avatar>
                  <div class="ml-1">
                    <div class="font-weight-bolder secondary-text">
                      {{ data.item.name }}
                    </div>
                  </div>
                </div>
              </b-link>
            </template>
            <template #cell(count)="data">
              <div class="d-flex flex-column">
                <span class="font-weight-bolder mb-25 orange-text">{{
                  data.item.count
                }}</span>
              </div>
            </template>
          </b-table>
        </b-card-body>
      </b-card>
    </b-col>
    <b-col sm="12">
      <b-card class="w-100" no-body>
        <b-card-header>
          <div class="pt-1">
            <b-card-sub-title>
              <h4>
                {{
                  $t(
                    "Pages.Assistant.Title.top_vid_revenue"
                  )
                }}
              </h4>
            </b-card-sub-title>
          </div>
        </b-card-header>
        <b-card-body>
          <b-table
            :items="tableDataEstimatedRevenue"
            responsive
            :fields="fieldsEstimatedRevenue"
            class="mb-0"
            :empty-text="
              $t(
                'Pages.Assistant.Table.not_mentorized_yet'
              )
            "
          >
            <template #cell(title)="data">
              <b-link>
                <div class="d-flex align-items-center">
                  <b-avatar rounded size="32" variant="light-company">
                    <b-img
                      class="img-fluid"
                      :src="data.item.thumbnail"
                      alt="avatar img"
                    />
                  </b-avatar>
                  <div class="ml-1">
                    <div class="font-weight-bolder secondary-text">
                      {{ data.item.name }}
                    </div>
                  </div>
                </div>
              </b-link>
            </template>
            <template #cell(count)="data">
              <div class="d-flex flex-column">
                <span class="font-weight-bolder mb-25 orange-text">{{
                  data.item.count
                }}</span>
              </div>
            </template>
          </b-table>
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BCardText,
  BAvatar,
  BTable,
  BImg,
  BBadge,
  BLink,
  BTooltip,
  BMedia,
  BMediaAside,
  BMediaBody,
  BCardHeader,
  BCardBody,
  BOverlay,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BForm,
  BButton,
  BCard,
  BAlert,
  BFormDatepicker,
  BInputGroup,
  BCardTitle,
  BModal,
  BInputGroupAppend,
  BPaginationNav,
  BCardSubTitle,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";

export default {
  name: "video-section-tables",
  components: {
    BRow,
    BCol,
    BCardText,
    BAvatar,
    BTable,
    BImg,
    BBadge,
    BLink,
    BTooltip,
    BMedia,
    BMediaAside,
    BMediaBody,
    BCardHeader,
    BCardBody,
    BOverlay,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BForm,
    BButton,
    BCard,
    BAlert,
    BFormDatepicker,
    BInputGroup,
    BCardTitle,
    BModal,
    BInputGroupAppend,
    BPaginationNav,
    BCardSubTitle,
    VueApexCharts,
  },
  data() {
    return {
      fieldsViews: [
        { key: "title", label: this.$t("Pages.Assistant.Table.Title") },
        { key: "count", label: this.$t("Pages.Assistant.Table.Count") },
      ],
      tableDataViews: [],
      fieldsComments: [
        { key: "title", label: this.$t("Pages.Assistant.Table.Title") },
        { key: "count", label: this.$t("Pages.Assistant.Table.Count") },
      ],
      tableDataComments: [],
      fieldsLikes: [
        { key: "title", label: this.$t("Pages.Assistant.Table.Title") },
        { key: "count", label: this.$t("Pages.Assistant.Table.Count") },
      ],
      tableDataLikes: [],
      fieldsDislikes: [
        { key: "title", label: this.$t("Pages.Assistant.Table.Title") },
        { key: "count", label: this.$t("Pages.Assistant.Table.Count") },
      ],
      tableDataDislikes: [],
      fieldsShares: [
        { key: "title", label: this.$t("Pages.Assistant.Table.Title") },
        { key: "count", label: this.$t("Pages.Assistant.Table.Count") },
      ],
      tableDataShares: [],
      fieldsEstimatedMinutesWatched: [
        { key: "title", label: this.$t("Pages.Assistant.Table.Title") },
        {
          key: "count",
          label: this.$t(
            "Pages.Assistant.Table.count_mint_watch"
          ),
        },
      ],
      tableDataEstimatedMinutesWatched: [],
      fieldsSubscribersGained: [
        { key: "title", label: this.$t("Pages.Assistant.Table.Title") },
        { key: "count", label: this.$t("Pages.Assistant.Table.Count") },
      ],
      tableDataSubscribersGained: [],
      fieldsSubscribersLost: [
        { key: "title", label: this.$t("Pages.Assistant.Table.Title") },
        { key: "count", label: this.$t("Pages.Assistant.Table.Count") },
      ],
      tableDataSubscribersLost: [],
      fieldsEstimatedRevenue: [
        { key: "title", label: this.$t("Pages.Assistant.Table.Title") },
        {
          key: "count",
          label: this.$t("Pages.Assistant.Table.count_est_revenue"),
        },
      ],
      tableDataEstimatedRevenue: [],
    };
  },
  async mounted() {
    this.getListTopVideoByViews();
    this.getListTopVideoByComments();
    this.getListTopVideoByLikes();
    this.getListTopVideoByDislikes();
    this.getListTopVideoByShares();
    this.getListTopVideoByEstimatedMinutesWatched();
    this.getListTopVideoBySubscribersGained();
    this.getListTopVideoBySubscribersLost();
    this.getListTopVideoByEstimatedRevenue();
  },
  methods: {
    getListTopVideoByViews() {
      this.$http
        .get(
          `/api/tools/assistant/table/top-video/views?id=${this.$route.params.channel_id}&uuid=${this.$route.params.uuid}`
        )
        .then((response) => {
          this.tableDataViews = response.data;
        });
    },
    getListTopVideoByComments() {
      this.$http
        .get(
          `/api/tools/assistant/table/top-video/comments?id=${this.$route.params.channel_id}&uuid=${this.$route.params.uuid}`
        )
        .then((response) => {
          this.tableDataComments = response.data;
        });
    },
    getListTopVideoByLikes() {
      this.$http
        .get(
          `/api/tools/assistant/table/top-video/likes?id=${this.$route.params.channel_id}&uuid=${this.$route.params.uuid}`
        )
        .then((response) => {
          this.tableDataLikes = response.data;
        });
    },
    getListTopVideoByDislikes() {
      this.$http
        .get(
          `/api/tools/assistant/table/top-video/dislikes?id=${this.$route.params.channel_id}&uuid=${this.$route.params.uuid}`
        )
        .then((response) => {
          this.tableDataDislikes = response.data;
        });
    },
    getListTopVideoByShares() {
      this.$http
        .get(
          `/api/tools/assistant/table/top-video/shares?id=${this.$route.params.channel_id}&uuid=${this.$route.params.uuid}`
        )
        .then((response) => {
          this.tableDataShares = response.data;
        });
    },
    getListTopVideoByEstimatedMinutesWatched() {
      this.$http
        .get(
          `/api/tools/assistant/table/top-video/estimatedMinutesWatched?id=${this.$route.params.channel_id}&uuid=${this.$route.params.uuid}`
        )
        .then((response) => {
          this.tableDataEstimatedMinutesWatched = response.data;
        });
    },
    getListTopVideoBySubscribersGained() {
      this.$http
        .get(
          `/api/tools/assistant/table/top-video/subscribersGained?id=${this.$route.params.channel_id}&uuid=${this.$route.params.uuid}`
        )
        .then((response) => {
          this.tableDataSubscribersGained = response.data;
        });
    },
    getListTopVideoBySubscribersLost() {
      this.$http
        .get(
          `/api/tools/assistant/table/top-video/subscribersLost?id=${this.$route.params.channel_id}&uuid=${this.$route.params.uuid}`
        )
        .then((response) => {
          this.tableDataSubscribersLost = response.data;
        });
    },
    getListTopVideoByEstimatedRevenue() {
      this.$http
        .get(
          `/api/tools/assistant/table/top-video/estimatedRevenue?id=${this.$route.params.channel_id}&uuid=${this.$route.params.uuid}`
        )
        .then((response) => {
          this.tableDataEstimatedRevenue = response.data;
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/chart-apex.scss";
</style>

<style>
.secondary-text,
.secondary-text a {
  color: var(--secondary) !important;
}
.orange-text {
  color: var(--orange);
}
</style>
